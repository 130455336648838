import React from 'react';

const LoginReturnSilentPage = () => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) return null;

  const LoginReturnSilent = React.lazy(() => import('../components/auth/LoginReturnSilent'));

  return (
    <React.Suspense fallback={null}>
      <LoginReturnSilent />
    </React.Suspense>
  );
}

export default LoginReturnSilentPage;
